<script>
import Chart from 'chart.js';
import { Line, mixins } from 'vue-chartjs';
import { chartTooltipStyles } from '@/core/setting';

const { reactiveProp } = mixins;

const fontFamily = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';

Chart.defaults.global.elements.point.radius = 4;
Chart.defaults.global.elements.point.pointStyle = 'round';
Chart.defaults.global.elements.point.backgroundColor = '#007bff';
Chart.defaults.global.elements.point.borderWidth = 0;

Chart.defaults.global.tooltips.borderWidth = 0;
Chart.defaults.global.tooltips.cornerRadius = 0;
Chart.defaults.global.tooltips.titleFontFamily = fontFamily;
Chart.defaults.global.tooltips.titleFontSize = 12;
Chart.defaults.global.tooltips.bodyFontFamily = fontFamily;
Chart.defaults.global.tooltips.bodyFontSize = 12;
Chart.defaults.global.tooltips.displayColors = false;

Chart.defaults.global.elements.line.backgroundColor = 'rgba(0, 123, 255, .1)';
Chart.defaults.global.elements.line.borderCapStyle = 'round';
Chart.defaults.global.elements.line.borderColor = '#007bff';
Chart.defaults.global.elements.line.borderWidth = 2;
Chart.defaults.global.elements.line.fill = true;

const fontStyle = {
  fontSize: 11,
  fontColor: '#212529',
  fontFamily,
};

export default {
  name: 'StatLineChart',
  mixins: [Line, reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      config: {
        yAxesTicks: {
          ...fontStyle,
        },
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 5,
          top: 0,
          bottom: 0,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              ...this.config.yAxesTicks,
              display: true,
              callback: (value) => `Wk ${value}`,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              ...this.config.yAxesTicks,
              display: true,
            },
          },
        ],
      },
      tooltips: {
        ...chartTooltipStyles,
        callbacks: {
          title: (tooltipItems) => {
            const { label } = tooltipItems[0];
            return `Week ${label}`;
          },
          label: (tooltipItem, data) => {
            const { index } = tooltipItem;
            const val = data.datasets[0].data[index];
            const d1 = data.datasets[0].d1[index];
            const d2 = data.datasets[0].d2[index];
            const { unit } = this;
            const labels = [`${val}${unit === '' ? '' : ` ${unit}`}`, '---', `${d1} - ${d2}`];
            return labels;
          },
        },
      },
    });
  },
};
</script>
